import FingerprintJS from "@fingerprintjs/fingerprintjs";
import Cookies from "js-cookie";

const FEEDBACK_COOKIE_NAME = "feedback_submission_id";
const COOKIE_EXPIRY_DAYS = 365;

// Konfigurasi keamanan cookie
const COOKIE_CONFIG = {
    expires: COOKIE_EXPIRY_DAYS,
    secure: true,
    sameSite: "strict" as const,
    path: "/",
};

export interface DeviceInfo {
    screen: {
        width: number;
        height: number;
        colorDepth: number;
    };
    hardware: {
        platform: string;
        cores: number;
        memory: number;
    };
    network: {
        connection: string;
        downlink: number;
    };
}

export interface BrowserInfo {
    userAgent: string;
    language: string;
    timeZone: string;
}

export interface TrackingData {
    fingerprint: string;
    cookieId: string;
    ipAddress: string;
    deviceInfo: DeviceInfo;
    browserInfo: BrowserInfo;
}

function generateUniqueId(): string {
    const array = new Uint8Array(16);
    crypto.getRandomValues(array);
    return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join("");
}

function setSecureCookie(name: string, value: string): void {
    Cookies.set(name, value, COOKIE_CONFIG);
    try {
        localStorage.setItem(`${name}_backup`, btoa(value));
    } catch (error) {
        console.error("Error backing up cookie:", error);
    }
}

function getSecureCookie(name: string): string | null {
    let value = Cookies.get(name);
    if (!value) {
        try {
            const encryptedValue = localStorage.getItem(`${name}_backup`);
            if (encryptedValue) {
                value = atob(encryptedValue);
                setSecureCookie(name, value);
            }
        } catch (error) {
            console.error("Error retrieving backup cookie:", error);
        }
    }
    return value || null;
}

function getDeviceInfo(): DeviceInfo {
    return {
        screen: {
            width: window.screen.width,
            height: window.screen.height,
            colorDepth: window.screen.colorDepth,
        },
        hardware: {
            platform: navigator.platform,
            cores: navigator.hardwareConcurrency || 0,
            memory: (navigator as any).deviceMemory || 0,
        },
        network: {
            connection: (navigator as any).connection?.effectiveType || "unknown",
            downlink: (navigator as any).connection?.downlink || 0,
        },
    };
}

function getBrowserInfo(): BrowserInfo {
    return {
        userAgent: navigator.userAgent,
        language: navigator.language,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
}

export async function getTrackingData(): Promise<TrackingData> {
    // Ambil cookie ID dengan metode aman
    let cookieId = getSecureCookie(FEEDBACK_COOKIE_NAME);
    if (!cookieId) {
        cookieId = generateUniqueId();
        setSecureCookie(FEEDBACK_COOKIE_NAME, cookieId);
    }

    // Ambil fingerprint
    let fingerprint = localStorage.getItem("device_fingerprint");
    if (!fingerprint) {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        fingerprint = result.visitorId;
        localStorage.setItem("device_fingerprint", btoa(fingerprint));
    } else {
        fingerprint = atob(fingerprint);
    }

    // Ambil IP address
    let ipAddress = "unknown"; // Default IP jika fetch gagal
    try {
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipResponse.json();
        ipAddress = ipData.ip;
    } catch (error) {
        console.error("Failed to fetch IP address:", error);
    }

    return {
        fingerprint,
        cookieId,
        ipAddress,
        deviceInfo: getDeviceInfo(),
        browserInfo: getBrowserInfo(),
    };
}

export async function checkFeedbackEligibility(): Promise<{
    eligible: boolean;
    reason?: string;
    waitingForVerification?: boolean;
}> {
    try {
        const trackingData = await getTrackingData();

        const response = await fetch("/api/check-feedback-eligibility", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(trackingData),
        });

        if (!response.ok) {
            throw new Error("Failed to check feedback eligibility.");
        }

        const data = await response.json();
        return {
            eligible: data.eligible,
            reason: data.reason || null,
            waitingForVerification: data.waitingForVerification || false,
        };
    } catch (error) {
        console.error("Error checking feedback eligibility:", error);
        return {
            eligible: false,
            reason: error instanceof Error ? error.message : "Error checking eligibility",
        };
    }
}
