"use client";

import { usePathname } from "next/navigation";
import FeedbackButton from "./feedback-button";

export default function ConditionalFeedback() {
  // Menggunakan usePathname hook untuk mendapatkan path saat ini
  const pathname = usePathname();
  
  // Memeriksa apakah ini adalah halaman muna-assistant
  const isMunaAssistantPage = pathname.includes("/muna-assistant");
  
  // Render FeedbackButton hanya jika bukan halaman muna-assistant
  if (isMunaAssistantPage) {
    return null;
  }
  
  return <FeedbackButton />;
}