"use client";

import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";
import FeedbackDialog from "@/components/feedback/feedback-dialog";
import { useTranslations } from "next-intl";
import { checkFeedbackEligibility } from "@/utils/feedback-tracking";
import { toast } from "sonner";

export default function FeedbackButton() {
    const t = useTranslations("FeedbackButton");
    const [isEligible, setIsEligible] = useState<boolean | null>(null);
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [waitingForVerification, setWaitingForVerification] = useState<boolean>(false); // Tambahkan state ini


    useEffect(() => {
        checkEligibility();
    }, []);

    const checkEligibility = async () => {
        setLoading(true);
        try {
            const { eligible, reason, waitingForVerification } = await checkFeedbackEligibility();
            setIsEligible(eligible);
            setWaitingForVerification(waitingForVerification ?? false);
    
            if (!eligible && reason) {
                toast.error(reason);
            }
        } catch (error) {
            console.error("Error checking eligibility:", error);
            setIsEligible(false);
        } finally {
            setLoading(false);
        }
    };
    

    const handleClick = async () => {
        if (loading) return;

        if (isEligible) {
            setShowDialog(true);
        } else {
            const { eligible, reason } = await checkFeedbackEligibility();
            if (eligible) {
                setIsEligible(true);
                setShowDialog(true);
            } else {
                toast.error(reason || t("notEligible"));
            }
        }
    };

    if (loading) return null;
    if (!isEligible) return null;

    return (
        <>
            <Button
                className="fixed bottom-4 left-4 z-50 text-sm"
                onClick={handleClick}
            >
                {t("tombol")}
            </Button>
            <FeedbackDialog 
                open={showDialog} 
                onOpenChange={setShowDialog}
                onSubmitSuccess={() => {
                    setIsEligible(false);
                    setShowDialog(false);
                }}
            />
        </>
    );
}
