"use client";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import "react-phone-number-input/style.css";
import { Turnstile } from "@marsidev/react-turnstile";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { StarIcon } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { toast } from "sonner";
import { useState } from "react";
import { useTheme } from "next-themes";
import { useTranslations } from 'next-intl';
import { useLocale } from 'next-intl';
import { getTrackingData } from "@/utils/feedback-tracking";
import React from "react";

const PhoneInput = React.lazy(() => import("react-phone-number-input"));



const feedbackSchema = (t: any) => z.object({
    title: z.string().default("Website Feedback"),
    rating: z.number().min(1, t("feedback.ratingRequired")),
    feedback: z.string().min(10, t("feedback.feedbackMinLength")),
    name: z.string().min(1, t("feedback.nameRequired")),
    email: z.string().email(t("feedback.invalidEmail")),
    phone: z.string().min(10, t("feedback.phoneRequired")),
});


type FeedbackDialogProps = {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onSubmitSuccess?: () => void;
};


export default function FeedbackDialog({ 
    open, 
    onOpenChange,
    onSubmitSuccess 
}: FeedbackDialogProps) {
    const locale = useLocale();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
    const { theme } = useTheme();
    const t = useTranslations('FeedbackDialog');

    const form = useForm<z.infer<ReturnType<typeof feedbackSchema>>>({
        resolver: zodResolver(feedbackSchema(t)),
        defaultValues: {
            title: "Website Feedback",
            rating: 0,
            feedback: "",
            name: "",
            email: "",
            phone: "",
        },
    });

    const onSubmit = async (data: any) => {
        if (!turnstileToken) {
            toast.error(t("feedback.turnstileRequired"));
            return;
        }

        try {
            setIsSubmitting(true);
    
            const trackingData = await getTrackingData();
            const response = await fetch("/api/submit-feedback", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    ...data,
                    locale,
                    ...trackingData,
                }),
            });
    
            const result = await response.json();
            if (!response.ok) {
                if (result.requiresVerification) {
                    toast.info("Silakan verifikasi email Anda.");
                } else {
                    throw new Error(result.error || "Gagal mengirimkan feedback.");
                }
            } else {
                toast.success("Feedback berhasil disimpan.");
            }
            form.reset();
            onOpenChange(false);
            onSubmitSuccess?.();
        } catch (error) {
            console.error("Error:", error);
            if (error instanceof Error) {
                toast.error(error.message);
            } else {
                toast.error("An unknown error occurred.");
            }
        } finally {
            setIsSubmitting(false);
        }
    };
    

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="sm:max-w-[425px] w-[95vw] mx-auto rounded-lg overflow-y-auto max-h-[90vh]">
                <DialogHeader>
                    <DialogTitle className="text-xl sm:text-2xl font-bold">
                        {t("feedback.title")}
                    </DialogTitle>
                    <DialogDescription className="text-sm sm:text-base">
                        {t("feedback.description")}
                    </DialogDescription>
                    <DialogDescription className="text-sm sm:text-base">
                        {t("feedback.caption")}
                    </DialogDescription>
                </DialogHeader>

                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                        <FormField
                            control={form.control}
                            name="rating"
                            render={({ field }) => (
                                <FormItem className="space-y-2">
                                    <FormLabel className="text-base font-medium">
                                        {t("feedback.ratingLabel")}
                                        <span className="text-red-500 ml-1">*</span>
                                    </FormLabel>
                                    <FormControl>
                                        <div className="flex gap-2 items-center justify-center sm:justify-start">
                                            {[1, 2, 3, 4, 5].map((rating) => (
                                                <Button
                                                    key={rating}
                                                    type="button"
                                                    variant="ghost"
                                                    size="sm"
                                                    onClick={() => field.onChange(rating)}
                                                    className={`
                            p-2 hover:bg-transparent
                            ${field.value >= rating
                                                            ? 'text-yellow-400 hover:text-yellow-500'
                                                            : 'text-gray-300 dark:text-gray-600 hover:text-yellow-400 dark:hover:text-yellow-400'}
                          `}
                                                >
                                                    <StarIcon className="h-6 w-6 sm:h-8 sm:w-8" />
                                                </Button>
                                            ))}
                                        </div>
                                    </FormControl>
                                    <FormMessage className="text-xs sm:text-sm" />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="feedback"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className="text-base font-medium">
                                        {t("feedback.feedbackLabel")}
                                        <span className="text-red-500 ml-1">*</span>
                                    </FormLabel>
                                    <FormControl>
                                        <Textarea
                                            placeholder={t("feedback.feedbackPlaceholder")}
                                            className="min-h-[120px] resize-none text-base"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage className="text-xs sm:text-sm" />
                                </FormItem>
                            )}
                        />

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <FormField
                                control={form.control}
                                name="name"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel className="text-base font-medium">
                                            {t("feedback.nameLabel")}
                                            <span className="text-red-500 ml-1">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder={t("feedback.namePlaceholder")}
                                                className="text-base"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage className="text-xs sm:text-sm" />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name="email"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel className="text-base font-medium">
                                            {t("feedback.emailLabel")}
                                            <span className="text-red-500 ml-1">*</span>
                                        </FormLabel>
                                        <FormControl>
                                            <Input
                                                type="email"
                                                placeholder={t("feedback.emailPlaceholder")}
                                                className="text-base"
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage className="text-xs sm:text-sm" />
                                    </FormItem>
                                )}
                            />
                        </div>

                        <FormField
                            control={form.control}
                            name="phone"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className="text-base font-medium">
                                        {t("feedback.phoneLabel")}
                                        <span className="text-red-500 ml-1">*</span>
                                    </FormLabel>
                                    <FormControl>
                                        <PhoneInput
                                            {...field}
                                            international
                                            defaultCountry="ID"
                                            className="w-full p-2 border rounded"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {/* Turnstile */}
                        <div className="mb-6">
                            <Turnstile
                                siteKey="0x4AAAAAAA29-0PYk7u7JLd5"
                                onSuccess={(token) => setTurnstileToken(token)}
                            />
                        </div>

                        <Button
                            type="submit"
                            className="w-full h-11 text-base font-medium"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <div className="flex items-center justify-center gap-2">
                                    <span className="h-5 w-5 animate-spin rounded-full border-2 border-white border-r-transparent"></span>
                                    {t("feedback.submitting")}
                                </div>
                            ) : (
                                t("feedback.submit")
                            )}
                        </Button>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
}